class AppState {
    exploreMode;

    constructor() {
        if(AppState.instance) {
            return AppState.instance;
        }
        AppState.instance = this;
    }

    static getInstance = () => {
        if(AppState.instance) {
            return AppState.instance;
        }
        return new AppState();
    }

    getExploreMode() {
        return this.exploreMode;
    }

    setExploreMode(exploreModeValue) {
        this.exploreMode = exploreModeValue;
    }
}

export default AppState;