import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ExperienceState from '../State/ExperienceState';
import AppConfig from '../AppConfig';
import AppState from '../AppState';
import * as Utilities from '../Util/Utilities';

let isApplicationInsightsInitialized = false;
const sourceValue = "Ludenso Explore Web/" + localStorage.getItem('version');

const EventParameter = {
    name: "name",
    title: "Title",
    publisher: "Publisher",
    version: "Version",
    uid: "Id",
    enrichmentUid: "EnrichmentUid",
    isPublished: "IsPublished",
    isARAvailable: "IsARAvailable",
    exploreMode: "ExploreMode",
    source: "Source",
    question: "Question",
    quiz: "Quiz"
};

const Event = {
    startOpenExperience: "Start Open Experience",
    finishOpenExperience: "Finished Open Experience",
    audioTriggered: "Audio Triggered",
    videoTriggered: "Video Triggered",
    linkTriggered: "Link Triggered",
    quizStarted: "Quiz Started",
    quizQuestionAnswered: "Quiz Question Answered",
    quizCompleted: "Quiz Completed"
};

const initializeApplicationInsights = () => {
    if(isApplicationInsightsInitialized) {
        return;
    }

    var connectionStringValue = AppConfig.getInstance().getAppInsightsConnectionString();

    var appInsights = new ApplicationInsights({
        config: {
            connectionString: connectionStringValue,
            enableAutoCollectExternalLoggers: false,
            enableAutoCollectConsole: false,
            enableLoggerErrorToTrace: false,
            enableAutoCollectExceptions: false,
            enableAutoCollectPerformance: false,
            enableAutoCollectExtendedMetrics: false,
            enableAutoCollectPreAggregatedMetrics: false,
            enableAutoCollectHeartbeat: false,
            enableAutoCollectRequests: false,
            enableAutoCollectDependencies: false,
            enableAutoDependencyCorrelation: false,
            enableAutoCollectIncomingRequestAzureFunctions: false,
            enableInternalDebugLogging: false,
            enableInternalWarningLogging: false,
            enableSendLiveMetrics: false,
            enableAutoRouteTracking: false,
            disableAjaxTracking: true,
            disableExceptionTracking: true,
            disableFetchTracking: true
        }
    });

    appInsights.loadAppInsights();
    appInsights.context.user.id = Utilities.getUserId();
    appInsights.context.session.id = Utilities.getSessionId();

    window.appInsights = appInsights;

    isApplicationInsightsInitialized = true;
}

const logEvent = (eventName, enrichmentUid, quizUid = "", questionUid = "") => {
    initializeApplicationInsights();
    var experienceMetadata = ExperienceState.getInstance().getSelectedExperience();
    if (!experienceMetadata) {
        return;
    }

    var event = getEvent(eventName);
    var properties = getDefaultProperties(experienceMetadata, enrichmentUid);
    if(quizUid) {
        properties[EventParameter.quiz] = quizUid;
    }
    if(questionUid) {
        properties[EventParameter.question] = questionUid;
    }

    window.appInsights.trackEvent(event, properties);
}

const getEvent = (eventName) => {
    var event = {};
    event[EventParameter.name] = eventName;
    return event;
}

const getDefaultProperties = (experienceMetadata, enrichmentUid) => {
    var properties = {};
    properties[EventParameter.uid] = experienceMetadata.uid;
    properties[EventParameter.title] = experienceMetadata.title;
    properties[EventParameter.publisher] = experienceMetadata.publisher;
    properties[EventParameter.version] = experienceMetadata.version;
    properties[EventParameter.isPublished] = experienceMetadata.isPublished;
    properties[EventParameter.isARAvailable] = false;
    if(enrichmentUid) {
        properties[EventParameter.enrichmentUid] = enrichmentUid;
    }
    properties[EventParameter.source] = sourceValue;
    properties[EventParameter.exploreMode] = AppState.getInstance().getExploreMode();
    return properties;
}

export const logStartOpenExperienceEvent = () => {
    logEvent(Event.startOpenExperience, null);
}

export const logFinishedOpenExperienceEvent = () => {
    logEvent(Event.finishOpenExperience, null);
}

export const logLinkInteractionTriggeredEvent = (enrichmentUid) => {
    logEvent(Event.linkTriggered, enrichmentUid);
}

export const logAudioInteractionTriggeredEvent = (enrichmentUid) => {
    logEvent(Event.audioTriggered, enrichmentUid);
}

export const logVideoInteractionTriggeredEvent = (enrichmentUid) => {
    logEvent(Event.videoTriggered, enrichmentUid);
}

export const logQuizStartedEvent = (enrichmentUid, quizUid) => {
    logEvent(Event.quizStarted, enrichmentUid, quizUid);
}

export const logQuizQuestionAnsweredEvent = (enrichmentUid, quizUid, questionUid) => {
    logEvent(Event.quizQuestionAnswered, enrichmentUid, quizUid, questionUid);
}

export const logQuizCompletedEvent = (enrichmentUid, quizUid) => {
    logEvent(Event.quizCompleted, enrichmentUid, quizUid);
}

