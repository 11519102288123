export const clearCache = async () => {
    var manifestURL = '/../../manifest.json?timestamp=' + Date.now();
    fetch(manifestURL)
        .then((response) => response.json())
        .then((manifestJson) => {
            let version = localStorage.getItem('version');
            console.log("Comparing stored version "+ version + " with served version " + manifestJson.version);
            if(version !== manifestJson.version) {
                if('caches' in window) {
                    caches.keys().then((names) => {
                        names.forEach(name => {
                            caches.delete(name);
                        })
                    });
                }
                localStorage.clear();
                localStorage.setItem('version', manifestJson.version);
                window.location.reload(true);
            }
        });
};