import EnrichmentType from './EnrichmentType';
import MiscConstants from './MiscConstants';

export const getEnrichmentIcon = (enrichmentType) => {
    var iconImage = MiscConstants.unknownContentIconImage;
    if(EnrichmentType.Video === enrichmentType) {
        iconImage = MiscConstants.videoIconImage;
    } else if(EnrichmentType.Link === enrichmentType) {
        iconImage = MiscConstants.linkIconImage;
    } else if(EnrichmentType.Model === enrichmentType) {
        iconImage = MiscConstants.modelIconImage;
    } else if(EnrichmentType.Audio === enrichmentType) {
        iconImage = MiscConstants.audioIconImage;
    } else if(EnrichmentType.Quiz === enrichmentType) {
        iconImage = MiscConstants.quizIconImage;
    }
    return iconImage;
}

const generateUniqueId = () => {
    return crypto.randomUUID();
}

const getGivenId = (storageKey) => {
    let id = localStorage.getItem(storageKey);
    if (!id || id === null) {
        id = generateUniqueId();
        localStorage.setItem(storageKey, id);
    }

    return id;
}

export const getUserId = () => {
    return getGivenId(MiscConstants.userIdLocalStorageKey);
}

export const invalidateSession = () => {
    localStorage.removeItem(MiscConstants.sessionIdLocalStorageKey);
}

export const getSessionId = () => {
    return getGivenId(MiscConstants.sessionIdLocalStorageKey);
}