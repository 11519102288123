class ExperienceMetadata {
    uid;
    thumbnailImage;
    title;
    publisher;
    version;
    isPublished;

    constructor(uid, thumbnailImage, title, publisher, version, isPublished) {
        this.uid = uid;
        this.thumbnailImage = thumbnailImage;
        this.title = title;
        this.publisher = publisher;
        this.version = version;
        this.isPublished = isPublished;
    }

    getThumbnailImage() {
        return this.thumbnailImage;
    }

    getTitle() {
        return this.title;
    }

    getPublisher() {
        return this.publisher;
    }
}

export default ExperienceMetadata;