import { useEffect } from 'react';
import ExperienceController from './ExperienceController';
import MiscConstants from '../Util/MiscConstants';
import QuizController from './QuizController';
import * as Utilities from '../Util/Utilities';

const AppController = () => {
    const quizUid = new URLSearchParams(window.location.search).get(MiscConstants.urlParamQuizUid);

    useEffect(() => {
        Utilities.invalidateSession();
    }, []);

    if(quizUid) {
        return (<QuizController />);
    } else {
        return (<ExperienceController />);
    }
};

export default AppController;