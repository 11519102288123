import * as OutsideClickHandler from '../Common/OutsideClickHandler';
import QuizViewer from './QuizViewer';

export default function QuizViewerWrapper({ enrichment, hideQuizViewerCallback }) {
    const viewerParentElementId = "quiz_viewer_parent";
    const quizViewerLayoutElementId = "quiz_viewer_layout";

    const quizViewerFinishedLoadingCallback = () => {
        OutsideClickHandler.setOutsideClickListener(viewerParentElementId, quizViewerLayoutElementId, hideQuizViewerCallback);
    }

    const closeQuizViewerCallback = () => {
        OutsideClickHandler.removeOutsideClickListener();
        if(hideQuizViewerCallback) {
            hideQuizViewerCallback();
        }
    }

    return (
        <div id="quiz_viewer_parent" className="modal-background">
            <div id="quiz_viewer_layout" className="quiz-viewer-layout">
                <QuizViewer
                    enrichment={enrichment}
                    quizViewerFinishedLoadingCallback={quizViewerFinishedLoadingCallback}
                    closeQuizViewerCallback={closeQuizViewerCallback} />
            </div>
       </div>
    );
}