import QuizQuestionOption from './QuizQuestionOption';

export default function QuizQuestion({ quizQuestion, currentQuestionNum, totalNumOfQuestions, optionSelectedCallback, closeQuizViewerCallback, onLoadingFinishedCallback }) {
    var keyIndex = 0;
    var optionIndex = 0;

    const generateOptionElementId = () => {
        optionIndex = optionIndex + 1;
        return getOptionElementId(optionIndex);
    }

    const getOptionElementId = (optionIndex) => {
        return "option" + optionIndex;
    }

    const optionClickedCallback = (clickedOptionIndex) => {
        let correctOptionIndex = 0;
        for(let i = 0; i < quizQuestion.options.length; i++) {
            let currentOptionIndex = i + 1;
            let currentElementId = getOptionElementId(currentOptionIndex);
            let currentOptionElement = document.getElementById(currentElementId);
            currentOptionElement.style.pointerEvents = "none";
            if(quizQuestion.options[i].isOptionCorrect === true) {
                correctOptionIndex = currentOptionIndex;
            }
        }

        let clickedElementId = getOptionElementId(clickedOptionIndex);
        let clickedOptionElement = document.getElementById(clickedElementId);
        let isClickedOptionCorrect = correctOptionIndex === clickedOptionIndex;
        if(isClickedOptionCorrect) {
            clickedOptionElement.className = clickedOptionElement.className + " right-answer";
        } else {
            clickedOptionElement.className = clickedOptionElement.className + " wrong-answer";
            let correctOptionElement = document.getElementById(getOptionElementId(correctOptionIndex));
            correctOptionElement.className = correctOptionElement.className + " right-answer";
        }

        setTimeout(function() { resetOptionStyles(clickedOptionIndex, isClickedOptionCorrect); }, 2000);
    }

    const getButtonStyle = (currentOptionIndex) => {
        switch(currentOptionIndex % 4) {
            case 1:
                return "button button-color1";
            case 2:
                return "button button-color2";
            case 3:
                return "button button-color3";
            default:
                return "button button-color4";
        }
    }

    const resetOptionStyles = (clickedOptionIndex, isClickedOptionCorrect) => {
        for(let i = 0; i < quizQuestion.options.length; i++) {
            let currentOptionIndex = i + 1;
            let currentElementId = getOptionElementId(currentOptionIndex);
            let currentOptionElement = document.getElementById(currentElementId);
            if(currentOptionElement) {
                currentOptionElement.style.pointerEvents = "";
                currentOptionElement.className = getButtonStyle(currentOptionIndex);
            }
        }
        optionSelectedCallback(quizQuestion.uid, isClickedOptionCorrect);
    }

    return (
        <div className="modal-layout quiz-question-content">
            <div className="content-item">
                <div className="breadcrumb">
                    <div className="content-item"><div className="text">Question {currentQuestionNum}/{totalNumOfQuestions}</div></div>
                    <div className="content-item-filler">&nbsp;</div>
                    {
                        closeQuizViewerCallback === null
                            ? null
                            : (<div className="content-item">
                                   <button onClick={closeQuizViewerCallback} className="viewer-close-button">
                                       <img src="images/close_icon.png" className="viewer-close-button-image" alt="Close modal" onLoad={onLoadingFinishedCallback}/>
                                   </button>
                               </div>)
                    }
                </div>
            </div>
            <div className="content-item"><div className="title">{quizQuestion.question}</div></div>
            <div className="content-item-filler">&nbsp;</div>
            {
                    quizQuestion.options
                        ? quizQuestion.options.map((option) =>
                            (<QuizQuestionOption
                              optionElementId={generateOptionElementId()}
                              optionIndex={optionIndex}
                              optionText={option.optionText}
                              buttonStyle={getButtonStyle(optionIndex)}
                              optionClickedCallback={optionClickedCallback}
                              key={keyIndex++} />)
                            )
                        : null
            }
            <div className="content-item">&nbsp;</div>
        </div>
    );
}