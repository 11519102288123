const MiscConstants = {
	urlParamDownloadExperienceUid: "downloadExperienceUid",
	urlParamQuizUid: "quizUid",
	modelIconImage: "./images/3d_model_icon.png",
	videoIconImage: "./images/video_icon.png",
	audioIconImage: "./images/audio_icon.png",
	linkIconImage: "./images/url_icon.png",
	quizIconImage: "./images/quiz_icon.png",
	unknownContentIconImage: "./images/unknown_content_icon.png",
	emptyUUID: "00000000-0000-0000-0000-000000000000",
	userIdLocalStorageKey: "com.ludensoexplore.userid",
	sessionIdLocalStorageKey: "com.ludensoexplore.sessionid"
}

export default MiscConstants;