import Config from "./config.json";

class AppConfig {
    modelViewerServer;
    endpointServer;
    assetBundleAPI;
    experienceAPI;
    thumbnailAPI;
    appInsightsConnectionString;
    showVideoEnrichments;
    showAudioEnrichments;
    showLinkEnrichments;
    showModelEnrichments;
    showQuizEnrichments;

    constructor() {
        if(AppConfig.instance) {
            return AppConfig.instance;
        }
        AppConfig.instance = this;
        this.#initializeFromConfig();
    }

    static getInstance = () => {
        if(AppConfig.instance) {
            return AppConfig.instance;
        }
        return new AppConfig();
    }

    #initializeFromConfig() {
        if(Config.currentEnv) {
            if(Config.currentEnv === 'Local') {
                AppConfig.instance.modelViewerServer = Config.modelViewerLocalServer;
                AppConfig.instance.endpointServer = Config.endpointLocalServer;
                AppConfig.instance.appInsightsConnectionString = Config.appInsightsLocalConnectionString;
            } else if(Config.currentEnv === 'Development') {
                AppConfig.instance.modelViewerServer = Config.modelViewerDevServer;
                AppConfig.instance.endpointServer = Config.endpointDevServer;
                AppConfig.instance.appInsightsConnectionString = Config.appInsightsDevConnectionString;
            } else if(Config.currentEnv === 'Production') {
                AppConfig.instance.modelViewerServer = Config.modelViewerProdServer;
                AppConfig.instance.endpointServer = Config.endpointProdServer;
                AppConfig.instance.appInsightsConnectionString = Config.appInsightsProdConnectionString;
            }
        }
        AppConfig.instance.assetBundleAPI = Config.assetBundleAPI;
        AppConfig.instance.experienceAPI = Config.experienceAPI;
        AppConfig.instance.thumbnailAPI = Config.thumbnailAPI;
        AppConfig.instance.showVideoEnrichments = Config.showVideoEnrichments;
        AppConfig.instance.showAudioEnrichments = Config.showAudioEnrichments;
        AppConfig.instance.showLinkEnrichments = Config.showLinkEnrichments;
        AppConfig.instance.showModelEnrichments = Config.showModelEnrichments;
        AppConfig.instance.showQuizEnrichments = Config.showQuizEnrichments;
    }

    getModelViewerServer() {
        return this.modelViewerServer;
    }

    getEndpointServer() {
        return this.endpointServer;
    }

    getAssetBundleAPI() {
        return this.endpointServer + this.assetBundleAPI;
    }

    getExperienceAPI() {
        return this.endpointServer + this.experienceAPI;
    }

    getThumbnailAPI() {
        return this.endpointServer + this.thumbnailAPI;
    }

    getAppInsightsConnectionString() {
        return this.appInsightsConnectionString;
    }

    getShowVideoEnrichments() {
        return this.showVideoEnrichments;
    }

    getShowAudioEnrichments() {
        return this.showAudioEnrichments;
    }

    getShowLinkEnrichments() {
        return this.showLinkEnrichments;
    }

    getShowModelEnrichments() {
        return this.showModelEnrichments;
    }

    getShowQuizEnrichments() {
        return this.showQuizEnrichments;
    }
}

export default AppConfig;