import { useState, useEffect } from 'react';
import ExperienceDetails from '../UI/Experience/ExperienceDetails';
import EnrichmentRows from '../UI/Experience/EnrichmentRows';
import * as EnrichmentAPIConsumer from '../Client/EnrichmentAPIConsumer';
import * as CacheManager from '../Util/CacheManager';
import AppState from '../AppState';
import ExploreMode from '../Util/ExploreMode';

const ExperienceController = () => {
    const [experience, setExperience] = useState([]);
    const [enrichments, setEnrichments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const dynamicTimestampSuffix = Date.now();
    const stickyTopMargin = 180;

    const setExperienceCallback = (experienceValue) => {
        setExperience(experienceValue);
    }

    const setEnrichmentsCallback = (enrichmentsValue) => {
        setEnrichments(enrichmentsValue);
    }

    const setIsLoadingCallback = (isLoadingValue) => {
        setIsLoading(isLoadingValue);
    }

    window.onscroll = function() {scrollFunction()};

    document.addEventListener("wheel", (event) => {
        if(event.deltaY < 0) {
            scrollFunction();
        }
    });

    function scrollFunction() {
        var subHeaderElement = document.getElementById("sub-header");
        var enrichmentRowsElement = document.getElementById("enrichment-rows");
        if(subHeaderElement !== null && enrichmentRowsElement !== null) {
            if (window.pageYOffset > stickyTopMargin) {
                subHeaderElement.classList.add("sticky-sub-header");
                enrichmentRowsElement.classList.add("sticky-enrichment-rows");
            } else {
                subHeaderElement.classList.remove("sticky-sub-header");
                enrichmentRowsElement.classList.remove("sticky-enrichment-rows");
            }
        }
    }

    useEffect(() => {
        CacheManager.clearCache();
        AppState.getInstance().setExploreMode(ExploreMode.webAppViewerMode);
        EnrichmentAPIConsumer.downloadExperienceAndQuiz(setExperienceCallback, setEnrichmentsCallback, null, setIsLoadingCallback);
    }, []);

    if (isLoading) {
        return (<div className="loading">Loading...</div>);
    } else {
        return (
            <div className="wrapper">
                <ExperienceDetails experience={experience} />
                <EnrichmentRows
                    enrichments={enrichments}
                    dynamicTimestampSuffix={dynamicTimestampSuffix} />
            </div>
        );
    }
};

export default ExperienceController;