import { useState } from 'react';
import ErrorDialogWrapper from '../ErrorDialog/ErrorDialogWrapper';
import ModelViewerWrapper from '../ModelViewer/ModelViewerWrapper';
import MediaPlayerWrapper from '../MediaPlayer/MediaPlayerWrapper';
import QuizViewerWrapper from '../Quiz/QuizViewerWrapper';
import EnrichmentRow from './EnrichmentRow';
import * as AnalyticsManager from '../../Analytics/AnalyticsManager';
import EnrichmentType from '../../Util/EnrichmentType';
import * as CacheManager from '../../Util/CacheManager';
import * as OutsideClickHandler from '../Common/OutsideClickHandler';

export default function EnrichmentRows({ enrichments, dynamicTimestampSuffix }) {
    var keyIndex = 0;

    const [displayError, setDisplayError] = useState(false);
    const [displayModelViewer, setDisplayModelViewer] = useState(false);
    const [displayMediaPlayer, setDisplayMediaPlayer] = useState(false);
    const [displayQuizViewer, setDisplayQuizViewer] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [selectedEnrichment, setSelectedEnrichment] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParam] = useState(["name", "pageNumber"]);

    const showErrorDialog = (message) => {
        setErrorMessage(message);
        setDisplayError(true);
    }

    const hideErrorDialogCallback = () => {
        setDisplayError(false);
    }

    const updateSelectedEnrichment = (enrichment, isGivenViewerAlreadyDisplayed) => {
        if(!isGivenViewerAlreadyDisplayed) {
            setSelectedEnrichment(enrichment);
        } else {
            setSelectedEnrichment(null);
        }
    }

    const showModelViewerCallback = async (enrichment) => {
        await CacheManager.clearCache();
        toggleBackgroundScrolling(false);
        if((!enrichment || !enrichment.assetBundleUid)
                && !displayModelViewer) {
            showErrorDialog("No model to display");
            return;
        }
        updateSelectedEnrichment(enrichment, displayModelViewer);
        setDisplayModelViewer(true);
    }

    const hideModelViewerCallback = () => {
        toggleBackgroundScrolling(true);
        setDisplayModelViewer(false);
    }

    const showMediaPlayerCallback = async (enrichment) => {
        await CacheManager.clearCache();
        toggleBackgroundScrolling(false);
        if((!enrichment || !enrichment.content)
                && !displayMediaPlayer) {
            showErrorDialog("No media to play");
            return;
        }
        updateSelectedEnrichment(enrichment, displayMediaPlayer);
        if(EnrichmentType.Video === enrichment.type) {
            AnalyticsManager.logVideoInteractionTriggeredEvent(enrichment.uid);
        } else if(EnrichmentType.Audio === enrichment.type) {
            AnalyticsManager.logAudioInteractionTriggeredEvent(enrichment.uid);
        }
        setDisplayMediaPlayer(true);
    }

    const toggleBackgroundScrolling = (enable) => {
        if(enable === true) {
            document.documentElement.style.overflow = "auto";
        } else {
            document.documentElement.style.overflow = "hidden";
        }
    }

    const hideMediaPlayerCallback = () => {
        toggleBackgroundScrolling(true);
        setDisplayMediaPlayer(false);
    }

    const showQuizViewerCallback = async (enrichment) => {
        await CacheManager.clearCache();
        toggleBackgroundScrolling(false);
        if((!enrichment || !enrichment.quiz)
                && !displayQuizViewer) {
            showErrorDialog("No quiz to display");
            return;
        }
        updateSelectedEnrichment(enrichment, displayQuizViewer);
        setDisplayQuizViewer(true);
    }

    const hideQuizViewerCallback = () => {
        toggleBackgroundScrolling(true);
        OutsideClickHandler.removeOutsideClickListener();
        setDisplayQuizViewer(false);
    }

    const openURLCallback = async (enrichment) => {
        await CacheManager.clearCache();
        if(enrichment && enrichment.content) {
            AnalyticsManager.logLinkInteractionTriggeredEvent(enrichment.uid);
            setSelectedEnrichment(enrichment);
            window.open(enrichment.content, '_blank').focus();
        } else{
            setSelectedEnrichment(null);
            showErrorDialog("No URL to open");
        }
    }

    const search = (items) => {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchQuery.toLowerCase()) > -1
                );
            });
        });
    }

    return (
        <div>
            {
                displayError
                    ? (<ErrorDialogWrapper
                        errorMessage={errorMessage}
                        hideErrorDialogCallback={hideErrorDialogCallback} />)
                    : null
            }
            <ModelViewerWrapper
               enrichment={selectedEnrichment}
               displayModelViewer={displayModelViewer}
               dynamicTimestampSuffix={dynamicTimestampSuffix}
               hideModelViewerCallback={hideModelViewerCallback} />
            {
                displayMediaPlayer
                    ? (<MediaPlayerWrapper
                        enrichment={selectedEnrichment}
                        hideMediaPlayerCallback={hideMediaPlayerCallback} />)
                    : null
            }
            {
                displayQuizViewer
                    ? (<QuizViewerWrapper
                        enrichment={selectedEnrichment}
                        hideQuizViewerCallback={hideQuizViewerCallback} />)
                    : null
            }
            <div id="sub-header" className="sub-header">
                <div className="content-item-filler enrichment-rows-margin">&nbsp;</div>
                <div className="content-item enrichment-rows-width">
                    <div className="sub-header-content">
                        <div className="content-item">
                            <div className="enrichments-title">Enrichments</div>
                        </div>
                        <div className="content-item">
                            <div className="search-wrapper">
                                <label htmlFor="search-form">
                                    <input
                                        type="search"
                                        name="search-form"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search enrichments or page number"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-item-filler enrichment-rows-margin">&nbsp;</div>
            </div>
            <div id="enrichment-rows" className="enrichment-rows">
                <div className="content-item-filler enrichment-rows-margin">&nbsp;</div>
                <div className="content-item enrichment-rows-width">
                    <ul className="card-grid">
                        {
                            enrichments
                                ? search(enrichments).map((enrichment) =>
                                    (
                                        <EnrichmentRow
                                            enrichment={enrichment}
                                            key={keyIndex++}
                                            showModelViewerCallback={showModelViewerCallback}
                                            showMediaPlayerCallback={showMediaPlayerCallback}
                                            openURLCallback={openURLCallback}
                                            showQuizViewerCallback={showQuizViewerCallback} />
                                    )
                                 )
                                : null
                        }
                    </ul>
                </div>
                <div className="content-item-filler enrichment-rows-margin">&nbsp;</div>
            </div>
        </div>
    );
}