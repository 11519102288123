import ModelViewer from './ModelViewer';

export default function ModelViewerWrapper({ enrichment, displayModelViewer, dynamicTimestampSuffix, hideModelViewerCallback }) {
    const viewerParentElementId = "model_viewer_parent";
    const modelViewerLayoutElementId = "model_viewer_layout";

    const fullscreenButtonCallback = (showFullscreen) => {
        var viewerParentElement = document.getElementById(viewerParentElementId);
        var modelViewerLayout = document.getElementById(modelViewerLayoutElementId);
        if(showFullscreen) {
            viewerParentElement.style.overflow = 'hidden';
            modelViewerLayout.className = "full-height-width";
        } else {
            viewerParentElement.style.overflow = 'auto';
            modelViewerLayout.className = "model-viewer-layout";
        }
    }

    const getParentStyleClass = () => {
        if(displayModelViewer) {
            return "modal-background";
        } else {
            return "hidden-style";
        }
    }

    return (
        <div id="model_viewer_parent" className={getParentStyleClass()}>
            <div id="model_viewer_layout" className="model-viewer-layout">
                <ModelViewer
                    enrichment={enrichment}
                    dynamicTimestampSuffix={dynamicTimestampSuffix}
                    displayModelViewer={displayModelViewer}
                    fullscreenButtonCallback={fullscreenButtonCallback}
                    closeModelViewerCallback={hideModelViewerCallback} />
            </div>
       </div>
    );
}