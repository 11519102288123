import { useState } from 'react';
import * as Utilities from '../../Util/Utilities';

export default function MediaPlayer({ enrichment, mediaPlayerFinishedLoadingCallback, closeMediaPlayerCallback }) {
    const [isLoading, setIsLoading] = useState(true);

    const onMediaPlayerLoadingFinishedCallback = () => {
        if(mediaPlayerFinishedLoadingCallback) {
            mediaPlayerFinishedLoadingCallback();
        }
    }

    const onLoadingFinishedCallback = () => {
        setIsLoading(false);
    }

    return (
        <div className="full-height-width">
            <div className="modal-layout media-player-content">
                <div className="viewer-title-panel">
                    <div className="content-item viewer-close-dummy-button viewer-close-button-image">&nbsp;</div>
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <img className="viewer-icon" src={Utilities.getEnrichmentIcon(enrichment.type)} alt={enrichment.name} loading="lazy" onLoad={onMediaPlayerLoadingFinishedCallback}/>
                    </div>
                    <div className="content-item overflow-text">
                        <div className="viewer-title">{enrichment.name}</div>
                    </div>
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <button onClick={closeMediaPlayerCallback} className="viewer-close-button">
                            <img className="viewer-close-button-image" src="./images/close_icon.png" alt="Close modal"/>
                        </button>
                    </div>
                </div>
                <div className="full-height-width viewer-modal-align">
                    {
                        isLoading
                            ? (<img className="modal-spinner" src="images/loading_icon.webp" alt="Loading"/>)
                            : null
                    }
                    <iframe
                        className="media-player-style"
                        onLoad={onLoadingFinishedCallback}
                        loading="lazy"
                        src={enrichment.content}
                        title="Media player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </div>
    );
}