export default function QuizComplete({ numOfCorrectAnswers, totalNumOfQuestions, closeQuizViewerCallback, restartQuizCallBack, onLoadingFinishedCallback }) {
    const twoStarsThreshold = 0.5;
    const threeStarsThreshold = 0.9;
    const starEmptyImage = "images/star_empty.png";
    const starFilledImage = "images/star_filled.png";

    const getQuizStarScore = () => {
        if(numOfCorrectAnswers <= 0) {
            return 0;
        } else if (numOfCorrectAnswers <= Math.floor(totalNumOfQuestions * twoStarsThreshold)) {
            return 1;
        } else if (numOfCorrectAnswers <= Math.floor(totalNumOfQuestions * threeStarsThreshold)) {
            return 2;
        } else {
            return 3;
        }
    }

    const starScore = getQuizStarScore();

    const getQuizCompleteText = (quizStarScore) => {
        if(quizStarScore === 0) {
            return "Keep trying!";
        } else if(quizStarScore === 1) {
            return "Good job!";
        } else if(quizStarScore === 2) {
            return "Nicely done!";
        } else if(quizStarScore === 3) {
            return "Great score!";
        }
    }

    const getStarImage = (quizStarScore, starNumber) => {
        return quizStarScore >= starNumber
            ? starFilledImage
            : starEmptyImage;
    }

    return (
        <div className="quiz-complete-height">
            <div className="half-height-full-width">
                <div className="quiz-complete-first-half no-margin">
                    <div className="content-item">
                        <div className="stars">
                            <div className="content-item-filler">&nbsp;</div>
                            <div className="content-item star leftstar">
                                <img src={getStarImage(starScore, 1)} className="star1" alt="One Star"/>
                            </div>
                            <div className="content-item star">
                                <img src={getStarImage(starScore, 2)} className="star2" alt="Two Star"/>
                            </div>
                            <div className="content-item star rightstar">
                                <img src={getStarImage(starScore, 3)} className="star3" alt="Three Star"/>
                            </div>
                            <div className="content-item-filler">&nbsp;</div>
                        </div>
                    </div>
                    <div className="content-item">
                        <img src="images/banner_completed.png" className="quiz-banner-completed" alt="Quiz Completed" onLoad={onLoadingFinishedCallback}/>
                    </div>
                </div>
            </div>
            <div className="half-height-full-width">
                <div className="modal-layout quiz-complete-second-half">
                    <div className="content-item"><div className="quiz-large-text quiz-complete-title-margin">{getQuizCompleteText(starScore)}</div></div>
                    <div className="content-item"><div className="quiz-large-text">{numOfCorrectAnswers}/{totalNumOfQuestions}</div></div>
                    <div className="content-item-filler">&nbsp;</div>
                    {
                        closeQuizViewerCallback === null
                            ? null
                            : (<div className="content-item">
                                   <div className="quiz-button" onClick={closeQuizViewerCallback}>
                                       <div className="text">Close</div>
                                   </div>
                               </div>)
                    }
                    <div className="content-item">
                        <div className="quiz-button" onClick={restartQuizCallBack}>
                            <div className="text">Try again</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}