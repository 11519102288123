export default function ExperienceDetails({ experience }) {
    return (
        <div id="header" className="header">
            <div className="content-item-filler">&nbsp;</div>
            <div className="content-item auto-margins">
                <img className="book-icon" src={experience.thumbnailImage} alt={experience.title} />
            </div>
            <div className="content-item auto-margins">
                <div className="book-details-wrapper">
                    <div className="content-item book-title">{experience.title}</div>
                    <div className="content-item book-publisher">{experience.publisher}</div>
                </div>
            </div>
            <div className="content-item-filler">&nbsp;</div>
        </div>
    );
}